import React, { useContext } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import GeneralAnchor from "../components/general-anchor/general-anchor.component";
import CalculatorBox from "../components/calculator-box/calculator-box.component";

import { MenuContext } from "../context";
import NewWindow from "../assets/new_window.inline.svg";

import "../styles/app.scss";

const BROKER_S_LINKS = [
  {
    title: "Contact us",
    href: "/broker-support",
  },
  {
    title: "Frequently asked questions",
    href: "/frequently-asked-questions",
    isLink: false,
  },
];

const PLEASE_READ_LINKS = [
  {
    title: "Criteria",
    href: "/criteria",
  },
  {
    title: "Latest news",
    href: "/newsroom",
  },
];

const LINKS_TO_HSBC = [
  {
    title: "Repayments calculator",
    desc: "Work out how much your monthly repayments could be",
    href: "https://www.hsbc.co.uk/mortgages/repayment-calculator/",
  },
  {
    title: "Overpayment calculator",
    desc: "See how overpaying your mortgage could reduce the term and overall interest paid",
    href: "https://www.hsbc.co.uk/mortgages/mortgage-overpayment-calculator/",
  },
];

export default function AffordabilityCalculator() {
  const menuContext = useContext(MenuContext);

  return (
    <Layout
      title="Affordability calculator"
      metaDescription="This quick and easy to use Affordability Calculator will provide an indication of how much HSBC UK could lend your customer(s). Please refer to our Residential Lending Criteria for further information."
    >
      <section className="calculator-page page-section row">
        <div className="col-xs-12 col-lg-7">
          <h2 className="page-title">Calculators</h2>
          <div className="row">
            <div className="col-xs-12 col-lg-6 calculator-column">
              <h3>Residential calculator</h3>
              <p>
                The Residential calculator will provide an indication of how much HSBC UK could lend your customer(s).
                Please refer to our
              </p>
              <p>
                <Link
                  className="inline-link"
                  to="/criteria/?tab=residential"
                  inert={menuContext.isMenuOpen ? "true" : null}
                >
                  Residential Criteria
                </Link>{" "}
                page for further information.
              </p>
              <GeneralAnchor
                href="https://portal.intermediaries.hsbc.co.uk/affordabilitycalculator/affordabilitycalculatorpage.php"
                isBlank
                isRed
                isLink={true}
              >
                Launch Residential calculator
                <NewWindow />
              </GeneralAnchor>
            </div>
            <div className="col-xs-12 col-lg-6 calculator-column">
              <h3>Buy to let calculator</h3>
              <p>
                The Buy to let calculator will provide an indication of whether HSBC UK could lend based on the expected
                rental income. Please refer to our
              </p>
              <p>
                <Link
                  className="inline-link"
                  to="/criteria/?tab=buy-to-let"
                  inert={menuContext.isMenuOpen ? "true" : null}
                >
                  Buy to let Criteria
                </Link>
                 page for further information.
              </p>
              <GeneralAnchor href="https://servicing.hsbc.co.uk/mortgages/btl-calculator/" isBlank isRed isLink={true}>
                Launch Buy to let calculator
                <NewWindow />
              </GeneralAnchor>
            </div>
          </div>
          <p>
            The amount your customer(s) could borrow may change based on full assessment of the application and
            following a satisfactory valuation, and does not constitute an offer to lend.
          </p>
          <p>Please complete a Decision in Principle to receive an accurate affordability amount.</p>
        </div>
        <div className="col-xs-12 col-lg-4 col-lg-offset-1">
          <CalculatorBox title="Broker support" links={BROKER_S_LINKS} />
          <CalculatorBox title="Please read" links={PLEASE_READ_LINKS} color="#00847F" />
          <CalculatorBox links={LINKS_TO_HSBC} color="#db0011" />
        </div>
      </section>
    </Layout>
  );
}
